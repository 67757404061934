.App {
    /*text-align: center;*/
    min-height: 100vh;
}

.ant-layout-header,
.ant-layout-footer {
    background: #333333;
    color: #fff;
    text-align: center;
}

.ant-layout-footer {
    line-height: 1.5;
}

.ant-layout-sider {
    background: #3ba0e9;
    color: #fff;
}

.ant-layout-content {
    min-height: 120px;
}

> .App > div > .ant-layout {
    margin-bottom: 48px;
}

    > .App > div > .ant-layout:last-child {
        margin: 0;
    }

.title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.home-icon {
    font-size: 12px;
    height: 90px;
    width: 90px;
    margin: 25px auto;
    border: solid 1px;
    padding: 8px;
    text-align: center;
}

    .home-icon i {
        font-size: 36px;
        margin-bottom: 5px;
    }
